export const employees = [
  {
    name: "Txus Portomeñe",
    fileName: "txus",
    position: "ceo",
  },
  {
    name: "Jose Gomez",
    fileName: "jose",
    position: "COO",
  },
  {
    name: "Marc Pujol",
    fileName: "marcpujol",
    position: "cto",
  },
  {
    name: "Leo Moyano",
    fileName: "leomoyano",
    position: "cpo",
  },
  {
    name: "Roger Galdón",
    fileName: "rogergaldon",
    position: "cso",
  },
  {
    name: "Alba Villalón",
    fileName: "albavillalon",
    position: "Full stack developer",
  },
  {
    name: "Alba Del Río",
    fileName: "albadelrio",
    position: "Frontend developer",
  },
  {
    name: "Antonio Raventós",
    fileName: "antonio",
    position: "Full stack developer",
  },
  {
    name: "Carla Martínez",
    fileName: "carlamartinez",
    position: "Frontend developer",
  },
  {
    name: "Daniel Cruz",
    fileName: "danielcruz",
    position: "Lead developer",
  },
  {
    name: "Daniel Moran",
    fileName: "danielmoran",
    position: "Lead developer",
  },
  {
    name: "David Muñoz",
    fileName: "davidmunoz",
    position: "Frontend Web Designer",
  },
  {
    name: "Diana Moreno",
    fileName: "diana",
    position: "Frontend developer",
  },
  {
    name: "Esther Cifuentes",
    fileName: "esther",
    position: "Project Manager",
  },
  {
    name: "Florencia Molina",
    fileName: "florenciamolina",
    position: "Project Manager",
  },
  {
    name: "Herman Añez",
    fileName: "herman",
    position: "Frontend developer",
  },
  {
    name: "Iolanda Rodriguez",
    fileName: "iolanda",
    position: "UX/UI designer",
  },
  {
    name: "Jesús Domínguez",
    fileName: "jesusdominguez",
    position: "Full stack developer",
  },
  {
    name: "Jordi Rosado",
    fileName: "jordirosado",
    position: "Sist Admin",
  },
  {
    name: "Jorge Neira",
    fileName: "jorgeneira",
    position: "Frontend developer",
  },
  {
    name: "José Julio Mújica",
    fileName: "josejulio",
    position: "Full stack developer",
  },
  {
    name: "Jose Luis Pavón",
    fileName: "jose-luis",
    position: "Backend developer",
  },
  {
    name: "Juan Hernandez",
    fileName: "juanantoniohernandez",
    position: "Backend developer",
  },
  {
    name: "Lluís Ruscalleda",
    fileName: "lluisruscalleda",
    position: "Full stack developer",
  },
  {
    name: "Maria Llenas",
    fileName: "mariallenas",
    position: "Frontend developer",
  },
  {
    name: "Marc Ferrer",
    fileName: "marcferre",
    position: "Sist Admin",
  },
  {
    name: "Marçal Sans",
    fileName: "marcalsans",
    position: "Full stack developer",
  },
  {
    name: "Natalia Rivera",
    fileName: "nataliarivera",
    position: "Project Manager",
  },
  {
    name: "Pako Lora",
    fileName: "pakolora",
    position: "Lead developer",
  },
  {
    name: "Rafa Pastor",
    fileName: "rafapastor",
    position: "Full stack developer",
  },
  {
    name: "Rebeca Mihura",
    fileName: "rebecamihura",
    position: "Analista SEO/SEM",
  },
  {
    name: "Sara Ramos",
    fileName: "sararamos",
    position: "Project manager",
  },
  {
    name: "Xavi Hernández",
    fileName: "xavihernandez",
    position: "Full stack developer",
  },
]
