import React from "react"
import { employees } from "../../../data/employees"
import buildResponsiveImages from "../../../helpers/buildResponsiveImages"
import tt from "../../../helpers/translation"
import styles from "../../../styles/about.module.scss"
import Layout from "../../Layout/Layout"
import MemberCard from "../../member-card/memberCard"
import NewSlider from "../../NewSlider/NewSlider"
import ResponsiveImage from "../../ResponsiveImage/ResponsiveImage"
import { SeeMoreButton } from "../../seeMoreButton/seeMoreButton"
import WhiteSection from "../../whiteSection/whiteSection"
import SliderBanner from "../../sliderBanner/SliderBanner"

const calculateSections = arr => {
  const mod = arr.length % 4

  if (mod === 0) {
    return arr.length / 4
  }
  return Math.floor(arr.length / 4) + 1
}

const AboutUs = ({
  lang,
  seoData,
  urls,
  aboutImagesDesktop,
  aboutImagesMobile,
  profileImages,
}) => {
  const numberOfSections = calculateSections(employees)

  return (
    <Layout
      leftContactUs
      lan={lang}
      location="about"
      contrast={true}
      seoData={seoData}
      urls={urls}
    >
      <WhiteSection
        first
        firstTitle={tt("Design & technology", lang)}
        subtitle={tt(
          "We develop innovative websites, apps and platforms for global brands, startups and organizations",
          lang
        )}
      />
      <SliderBanner
        slides={buildResponsiveImages(
          aboutImagesDesktop,
          aboutImagesMobile
        ).map((item, index) => {
          return <ResponsiveImage key={index} alt="" fluid={item.fluid} />
        })}
      />
      {/* The next code is commented because it didn’t respond well when
      directly loading on the phone a certain route. Should be improved in order to use it instead of current solution
due to the current need to load js to display */}
      {/* <NewSlider
        slides={buildResponsiveImages(
          aboutImagesDesktop,
          aboutImagesMobile
        ).map((item, index) => {
          return <ResponsiveImage key={index} alt="" fluid={item.fluid} />
        })}
        className={styles.slider}
      ></NewSlider> */}
      <WhiteSection
        title={tt("About us", lang)}
        subtitle={tt(
          "Interficie is a full service digital agency with a passionate team of designers, digital creators & product innovators.",
          lang
        )}
        text={tt(
          "Interficie is an Internet development studio, based in Barcelona, which offers consultancy and development of Internet solutions for global brands, startups and public institutions.\nWe are always looking for people committed and proactive who want to join our team and grow with us.\nWe are a company created and managed by developers. We offer a pleasant work environment in offices located in the center of Barcelona. The benefits we offer help create a good working ecosystem.\n35 hours per week, intensive day, work planning and mentoring by the most experienced people. If you believe in this way of working, visit our positions section and get in touch with us.",
          lang
        )}
      />
      <div className={styles.desktop_card_set_container}>
        <div className={styles.desktop_card_set}>
          {employees.map((item, index) => (
            <MemberCard
              key={index}
              name={item.name}
              fileName={item.fileName}
              position={item.position}
              fotos={profileImages.nodes}
            />
          ))}
        </div>
      </div>
      <div className={styles.mobile}>
        <SliderBanner
          slides={[...Array(numberOfSections)].map((item, index) => (
            <div key={index} className={styles.four_card_set}>
              {employees
                .slice(index * 4, index * 4 + 4)
                .map((_item, _index) => (
                  <MemberCard
                    key={_index}
                    name={_item.name}
                    fileName={_item.fileName}
                    position={_item.position}
                    fotos={profileImages.nodes}
                  />
                ))}
            </div>
          ))}
        />
      </div>
      {/* The next code is commented because it didn’t respond well when
      directly loading on the phone a certain route. Should be improved in order to use it instead of current solution
due to the current need to load js to display */}
      {/* <NewSlider
        slides={[...Array(numberOfSections)].map((item, index) => (
          <div key={index} className={styles.four_card_set}>
            {employees.slice(index * 4, index * 4 + 4).map((_item, _index) => (
              <MemberCard
                key={_index}
                name={_item.name}
                fileName={_item.fileName}
                position={_item.position}
                fotos={profileImages.nodes}
              />
            ))}
          </div>
        ))}
      ></NewSlider> */}
      <div className={styles.blue_section_container}>
        <div className={styles.first_section}>
          <div className={styles.blue_text_container}>
            <p>{tt("Do you want to be part of our team?", lang)}</p>
            <SeeMoreButton
              text={tt("Our careers", lang)}
              sendTo={tt("/careers/", lang)}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
