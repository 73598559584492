import React from "react"
import PropTypes from "prop-types"
import styles from "./index.module.scss"
import Image from "gatsby-image"

const MemberCard = ({ name, fileName, position, fotos }) => {
  const { fluid } = fotos.find(
    foto => foto.childImageSharp.fluid.src.indexOf(fileName) > -1
  ).childImageSharp
  return (
    <div className={styles.wrapper}>
      <Image alt="" fluid={fluid} />
      <span className={styles.name}>{name}</span>
      <span className={styles.position}>{position}</span>
    </div>
  )
}

MemberCard.propTypes = {
  name: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  fotos: PropTypes.array.isRequired,
}

export default MemberCard
